import { combineReducers } from 'redux';

import { connectRouter } from 'connected-react-router';

import auth from './auth';
import common from './common';
import resource from './resource';
import policy from './policy';
import role from './role';
import user from './user';
import advertiser from './advertiser';
import store from './store';
import offer from './offer';
import banners from './banners';
import category from './category';
import remoteConfig from './remoteConfig';
import customCategory from './customCategory';
import PrimeOffer from './primeOffer';
import EliteOffer from './eliteOffer';
import PrimeOfferCallbacks from './primeOfferCallbacks';
import additionalCashback from './additionalCashback';
import userReport from './userReport';
import offlineSaleReports from './OfflineSaleReporting';
import offlinePrimeOfferReports from './OfflinePrimeOfferReporting';
import offlineEliteOfferReports from './OfflineEliteOfferReporting';
import offlineShareOfferReports from './OfflineShareOfferReporting';
import customBillerCategory from './PaymentDashboard/customBillerCategory';
import biller from './PaymentDashboard/biller';
import billerCategory from './PaymentDashboard/billerCategory';
import refund from './PaymentDashboard/refund';
import campaign from './campaign';
import cpatCampaign from './cpatCampaign';
import influencer from './influencer';
import ShareOffer from './shareOffer';

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  auth,
  role,
  user,
  store,
  offer,
  banners,
  common,
  PrimeOfferCallbacks,
  policy,
  resource,
  category,
  advertiser,
  remoteConfig,
  customCategory,
  PrimeOffer,
  EliteOffer,
  additionalCashback,
  userReport,
  offlineSaleReports,
  offlinePrimeOfferReports,
  offlineEliteOfferReports,
  offlineShareOfferReports,
  customBillerCategory,
  biller,
  billerCategory,
  refund,
  campaign,
  cpatCampaign,
  influencer,
  ShareOffer,
});

export default createRootReducer;
