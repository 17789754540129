import {
  CREATE_BANNER,
  UPDATE_BANNER,
  UNLOAD_BANNER,
  BANNER_FETCHED_SUCCESS,
  RESET_BANNER,
  UPDATE_ACTION_POOL,
  PUSHED_BANNER,
  BANNER_TOGGLE_FAILED,
  BANNER_TOGGLE_SUCCESS,
} from '../constants/actionTypes';

const initialState = {
  banners: [],
  actionPool: [],
  message: '',
  pager: {
    current: 1,
    pageSize: 15,
    total: 0,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_BANNER:
    case UPDATE_BANNER:
      return {
        ...state,
        message: action.payload.msg,
      };

    case RESET_BANNER:
      return {
        ...state,
        message: '',
      };

    case BANNER_FETCHED_SUCCESS:
      return {
        ...state,
        banners: action.payload.banners.map((item) => {
          const newItem = { ...item, id: item.id };
          return newItem;
        }),
        pager: action.payload.pager,
      };

    case UPDATE_ACTION_POOL:
      return {
        ...state,
        actionPool: action.payload.data,
      };

    case PUSHED_BANNER:
      return {
        ...state,
        banners: state.banners.map((item) => {
          if (item.id === action.payload.id) {
            const newItem = { ...item };
            newItem.pushed_at = action.payload.date;
            return newItem;
          }
          return item;
        }),
      };

    case UNLOAD_BANNER:
      return initialState;

    case BANNER_TOGGLE_FAILED:
      return state;

    case BANNER_TOGGLE_SUCCESS:
      return {
        ...state,
        banners: state.banners.map((item) => {
          if (item.id !== action.payload.id) return item;
          const newItem = { ...item };
          newItem.is_active = action.payload.active;
          return newItem;
        }),
      };

    default:
      return state;
  }
};
