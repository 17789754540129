import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  CheckOutlined,
  CloseOutlined,
  SearchOutlined,
  CalendarOutlined,
  EyeOutlined,
  LockFilled,
  UnlockFilled,
  CheckCircleFilled,
  CloseCircleFilled,
} from '@ant-design/icons';

import {
  Tag, Switch, Tooltip, Button, Select, DatePicker,
  Checkbox,
} from 'antd';
import {
  debounce, get, difference, pickBy, identity, without,
} from 'lodash';

import PopConfirm from '../containers/PopConfirm';

import PushButton from '../containers/PushButton';
import EditButton from '../containers/EditButton';
import { handleSearch } from './common';

export const idColumn = (
  title = 'Id',
  dataIndex = 'id',
  sorter = false,
  { ...rest } = {},
) => ({
  title,
  dataIndex,
  sorter,
  ellipsis: true,
  ...rest,
});

export const nameColumn = (
  title = 'Name',
  dataIndex = 'name',
  sorter = true,
  { ...rest } = {},
) => ({
  title,
  dataIndex,
  sorter,
  ellipsis: true,
  ...rest,
});

export const downloadLinkColumn = (
  title = 'Link',
  dataIndex = 'link',
  sorter = true,
  { ...rest } = {},
) => ({
  title,
  dataIndex,
  sorter,
  render: (name) => (
    <a href={name}>{name}</a>
  ),
  ellipsis: true,
  ...rest,
});

export const isLockedColumn = (
  title = 'Locked',
  dataIndex = 'is_locked',
  sorter = true,
  { ...rest } = {},
) => ({
  title,
  dataIndex,
  sorter,
  render: (value) => (value ? (
    <LockFilled style={{ fontSize: 20 }} />
  ) : (
    <UnlockFilled style={{ fontSize: 20 }} />
  )),
  ellipsis: true,
  ...rest,
});

export const isValidColumn = (
  title = 'Valid',
  dataIndex = '',
  sorter = true,
  { ...rest } = {},
) => ({
  title,
  dataIndex,
  sorter,
  render: (record) => {
    if (!record.is_locked && (record.status === 'PENDING' || record.status === 'IN_PROGRESS')) return <CheckCircleFilled style={{ fontSize: 20 }} />;
    return <CloseCircleFilled style={{ fontSize: 20 }} />;
  },
  ellipsis: true,
  ...rest,
});

export const grantColumn = (
  title = 'Grant',
  dataIndex = '',
  sorter = true,
  onGrantChange,
  { ...rest } = {},
) => ({
  title,
  dataIndex,
  sorter,
  render: (record) => {
    const data = record;
    return <Checkbox disabled={data.is_locked || (data.status !== 'PENDING' && data.status !== 'IN_PROGRESS')} onChange={(e) => { data.granted = e.target.checked; onGrantChange(record.id, e.target.checked); }} />;
  },
  ellipsis: true,
  ...rest,
});

export const priorityColumn = (
  title = 'Priority',
  dataIndex = 'priority',
  sorter = true,
  { ...rest } = {},
) => ({
  title,
  dataIndex,
  sorter,
  ellipsis: true,
  ...rest,
});

export const descriptionColumn = (
  title = 'Description',
  dataIndex = 'description',
) => ({
  title,
  dataIndex,
  ellipsis: true,
});

export const emailColumn = (
  title = 'Email',
  dataIndex = 'email',
  sorter = true,
) => ({
  title,
  dataIndex,
  sorter,
});

export const withTooltip = (
  title,
  dataIndex,
  tooltipIndex,
  { ...rest } = {},
) => ({
  title,
  dataIndex,

  render: (name, record) => (

    <Tooltip title={get(record, tooltipIndex)}>
      <span>{name}</span>
    </Tooltip>
  ),
  ellipsis: true,
  ...rest,
});

export const withTag = (
  title,
  dataIndex,
  { color = 'blue', ...rest } = {},
) => ({
  title,
  dataIndex,
  render: (name, record) => {
    let upperName = name && name.toString().toUpperCase();
    if (name !== true && name !== false && Number.isNaN(name)) {
      upperName = name && name.toUpperCase();
    }
    if (rest.filters) {
      const filterData = rest.filters.filter((item) => item.value === name);
      upperName = filterData[0]?.text;
    } else if (name === true || name === false) {
      upperName = name === true ? 'ACTIVE' : 'DISABLED';
    }
    return (
      upperName && (

        <Tag
          key={record.id}
          color={
            typeof color === 'string'
              ? color
              : color[upperName]
                ? color[upperName]
                : color
          }
        >
          {upperName}
        </Tag>
      )
    );
  },
  ...rest,
});

export const eliteOfferStatusTag = (
  title,
  dataIndex,
  { color = 'green', ...rest } = {},
) => ({
  title,
  dataIndex,
  render: (name, record) => name && (
    <Tag
      key={record.id}
      color={
        typeof color === 'string'
          ? color
          : color[name]
            ? color[name]
            : color
      }
    >
      {name}
    </Tag>
  ),
  ...rest,
});

export const withOfferTag = (
  title,
  dataIndex,
  { color = 'blue', ...rest } = {},
) => ({
  title,
  dataIndex,
  render: (name, record) => {
    let upperName = title && title.toUpperCase();
    if (name === true || name === false) {
      upperName = name === true ? 'ENABLED' : 'DISABLED';
    }

    if (name !== true && name !== false) {
      upperName = name ? 'PRESENT' : 'ABSENT';
    }

    return (

      <Tag
        key={record.id}
        color={
              typeof color === 'string'
                ? color
                : color[upperName]
                  ? color[upperName]
                  : color
            }
      >
        {upperName}
      </Tag>
    );
  },
  ...rest,
});

const switchStyle = { display: 'block', margin: '0 auto' };

export const activeColumn = (
  onClick,
  render,
  { ...rest } = {},
  popConfirms = false,
  disabled = false,
) => ({
  title: 'Active',
  dataIndex: 'active',
  filters: [
    { text: 'Active', value: 1 },
    { text: 'Inactive', value: 0 },
  ],
  render: (active, record) => (render ? (
    render(active, record)
  ) : popConfirms ? (
    <PopConfirm
      tagBlock={onClick}
      record={record}
      id={record.id}
      status={active}
    />
  ) : (
    <Switch
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      checked={active}
      size="small"
      disabled={disabled}
      style={switchStyle}
      onClick={(checked) => onClick(record.id, checked, record)}
    />
  )),
  width: 100,
  ...rest,
});

const isDisable = (record, disabled) => {
  // if resource is not pushed at
  if (record.pushed_at === null) return false;
  // if resource is pushed but not updated once then disable push btn
  if (record.modified_at === null) return true;
  // now resource has been pushed & updated too
  return disabled && typeof disabled === 'function'
    ? (record.modified_at && record.modified_at <= record.pushed_at)
        || disabled(record)
    : record.modified_at && record.modified_at <= record.pushed_at;
};

const tooltipData = (record) => {
  const tnc = record.tnc
    ? without(
      record.tnc.split(/[\n|<p>|</p>]+/),
      '',
      'ul',
      '</ul>',
      'li',
      '</li>',
      '\t',
      'strong',
    )
    : '';

  let obj = {
    CTA: record?.cta_text || '',
    Deeplink: record?.deeplink || '',
    'Discount Percentage': record?.discount_percentage || '',
    'Sub Description': record?.sub_description || '',
    Subcategory:
        record.sub_categories && record.sub_categories.length > 0
          ? record.sub_categories[0].name
          : '',
    'Coupon Code': record.coupon_code ? record.coupon_code : null,
    TnC: tnc,
    Payout:
        record.payout_type && record.payout_type === 'FLAT'
          ? `Rs. ${record.payout} Cashback`
          : record.payout_type === 'PERCENTAGE'
            ? `${record.payout}% Cashback`
            : null,
    Revenue: record.revenue ? record.revenue : '',
    'Maximum Cashback Allowed': record.max_cashback
      ? `Rs. ${record.max_cashback}`
      : null,
    'Minimum Sale Amount': record.min_sale_order
      ? `Rs. ${record.min_sale_order}`
      : null,
  };

  obj = { ...pickBy(obj, identity) };
  return obj;
};

const EyeOutlinedStyles = {
  margin: '0 7px', textAlign: 'center', fontSize: '20px', color: '#1890FF',
};
const rootActionStyles = { whiteSpace: 'nowrap', display: 'flex', columnGap: '10px' };
const itemStyles = { margin: '4px 0 4px 0', color: '#000', fontSize: '14px' };
export const actionColumn = (
  redirectTo,
  {
    pushButton = false,
    resource,
    permissionKey,
    disabled,
    dataIndex = 'id',
    title = 'Actions',
    passRecord = false,
  } = {},
  {
    tooltipActive = false,
  } = {},
  {
    onClick,
    status = false,
  } = {},
  { ...rest } = {},
) => ({
  title,
  dataIndex,
  key: 'action',
  render: (id, record) => (
    <div style={rootActionStyles}>
      {status
      && (
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        size="small"
        disabled={disabled}
        checked={record.is_active}
        style={{ alignSelf: 'center', background: `${record.is_active ? '#2CEF00' : '#BFBFBF'}` }}
        onClick={(checked) => onClick(id, checked)}
      />
      )}

      {tooltipActive && (
        <Tooltip
          overlay={(
            <>
              <h2>Offer Details</h2>
              {Object.entries(tooltipData(record)).map(([key, value]) => {
                if (key === 'TnC') {
                  return (
                    <p style={itemStyles}>
                      <span style={{ fontWeight: '700' }}>{`${key}: `}</span>
                      <ul>{value.map((item) => <li key={item}>{item}</li>)}</ul>
                    </p>
                  );
                }
                return (
                  <p style={itemStyles}>
                    <span style={{ fontWeight: '700' }}>{`${key}: `}</span>
                    <span>{value}</span>
                  </p>
                );
              })}
            </>
        )}
          color="#91D5FF"
          overlayStyle={{ width: '338px' }}
        >
          <EyeOutlined style={EyeOutlinedStyles} />
        </Tooltip>
      )}

      {passRecord ? (
        <NavLink to={redirectTo(record)}>
          <EditButton permissionKey={permissionKey} record={record} />
        </NavLink>
      ) : (
        <NavLink to={redirectTo(id)}>
          <EditButton permissionKey={permissionKey} record={record} />
        </NavLink>
      )}

      {pushButton && (
        <PushButton
          id={id}
          disabled={isDisable(record, disabled)}
          resource={resource}
          permissionKey={permissionKey}
        />
      )}
    </div>
  ),
  width: 80,
  ...rest,
});

const iconStyle = {
  width: 80,
  maxWidth: '100%',
};

export const iconColumn = (title = 'Icon', dataIndex = 'icon') => ({
  title,
  dataIndex,
  render: (url) => (
    <img src={url} style={iconStyle} alt="" />
  ),
  width: 80,
});

export const multiItemColumn = (
  title = 'Article Category',
  dataIndex = 'id',
  { ...rest } = {},
) => ({
  title,
  dataIndex,
  render: (items) => (
    <>
      {items.slice(0, 4).map((item) => (
        <Tag key={item.id} color="blue">
          {item.name}
        </Tag>
      ))}
      {items.length > 4 && (
      <Tag color="#ff4d4f">
        +
        {items.length - 4}
        {' '}
        more
      </Tag>
      )}
    </>
  ),
  width: 210,
  ...rest,
});

export const timeColumn = (
  title,
  dataIndex,
  sorter = false,
  { ...rest } = {},
) => ({
  title,
  dataIndex,
  render: (time) => time && time.toLocaleString(),
  sorter,
  ellipsis: true,
  ...rest,
});

const inputStyle = {
  minWidth: 200,
  maxWidth: 300,
  marginBottom: 10,
  display: 'block',
};

const primaryStyle = { width: 90, marginRight: 8 };

class SearchInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searching: false,
      data: [],
    };
    this.handleSearch = handleSearch.bind(this);
    this.searchFilterOption = debounce(
      this.handleSearch(this.props.source, 'data', this.props.extraParams),
      250,
    );

    this.unlisten = null;
  }

  componentDidMount() {
    const { type, selectedKeys, history } = this.props;

    if (type === 'search' && selectedKeys.length) {
      this.searchFilterOption({ id: selectedKeys });

      this.unlisten = history.listen((location, action) => {
        if (this.props.location.pathname !== location.pathname) {
          return;
        }

        // there will be all values in data if action === push
        if (action === 'POP') {
          const _selectedKeys = this.props.selectedKeys;

          const diff = difference(
            _selectedKeys,
            this.state.data.map((item) => item.value),
          );
          // if there is any difference means there is some key without option
          if (diff.length) {
            this.searchFilterOption({ id: _selectedKeys });
          }
        }
      });
    }
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  render() {
    const { searching, data } = this.state;
    const { type } = this.props;

    if (type === 'search') {
      return (
        <Select
          showSearch
          mode="multiple"
          tokenSeparators={[',']}
          defaultActiveFirstOption={false}
          suffixIcon={false}
          filterOption={false}
          loading={Boolean(searching === this.props.source)}
          onSearch={this.searchFilterOption}
          notFoundContent={searching ? 'Fetching data' : 'Not found anything'}
          style={inputStyle}
          onChange={(value) => {
            this.props.setSelectedKeys(value);
          }}
          ref={this.props.forewardRef}
          value={this.props.selectedKeys}
          placeholder="Type here"
        >
          {data.length
            ? data.map((d) => (
              <Select.Option key={d.value} value={d.value}>
                {d.text}
              </Select.Option>
            ))
            : null}
        </Select>
      );
    }

    if (type === 'date') {
      return (
        <DatePicker.RangePicker
          format="YYYY-MM-DD HH:mm"
          showTime={{ format: 'HH:mm' }}
          placeholder={['Start Date', 'End Date']}
          style={inputStyle}
          ref={this.props.forewardRef}
          value={this.props.selectedKeys}
          onChange={(value) => {
            this.props.setSelectedKeys(value);
          }}
        />
      );
    }

    return null;
  }
}

export const getColumnSearchProps = (dataIndex, { source, type }, params) => {
  const searchInput = React.createRef();

  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      visible,
    }) => (
      <div style={{ padding: 10 }}>
        <SearchInput
          type={type}
          source={source}
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          forewardRef={searchInput}
          visible={visible}
          confirm={confirm}
          clearFilters={clearFilters}
          extraParams={params}
        />
        <Button
          type="primary"
          onClick={confirm}
          icon={<SearchOutlined />}
          size="small"
          style={primaryStyle}
        >
          {type === 'search' ? 'Search' : 'Apply'}
        </Button>
        <Button onClick={clearFilters} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => {
      if (type === 'search') {
        return (
          <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
        );
      }
      return (
        <CalendarOutlined
          style={{ color: filtered ? '#1890ff' : undefined, fontWeight: 800 }}
        />
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current.focus());
      }
    },
    type,
  };
};
