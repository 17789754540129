/*
 * action types
 */
export const APP_LOADED = 'APP_LOADED';
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';

export const LOGIN = 'LOGIN';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCCESSFULL = 'LOGIN_SUCCESSFULL';
export const LOGOUT = 'LOGOUT';

export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';

export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';

// =====================================================================
// RESOURCES
// =====================================================================

export const ADD_RESOURCE = 'ADD_RESOURCE';
export const UPDATE_RESOURCE = 'UPDATE_RESOURCE';
export const UNLOAD_RESOURCE = 'UNLOAD_RESOURCE';

export const RESOURCE_TOGGLE_STATUS = 'RESOURCE_TOGGLE_STATUS';
export const RESOURCE_TOGGLE_SUCCESS = 'RESOURCE_TOGGLE_SUCCESS';
export const RESOURCE_TOGGLE_FAILED = 'RESOURCE_TOGGLE_FAILED';

export const FETCH_RESOURCE_LIST = 'FETCH_RESOURCE_LIST';
export const RESOURCE_FETCHED_SUCCESS = 'RESOURCE_FETCHED_SUCCESS';
export const RESOURCE_FETCHED_FAILED = 'RESOURCE_FETCHED_FAILED';
export const RESOURCE_DELETE_SUCCESS = 'RESOURCE_DELETE_SUCCESS';
export const RESOURCE_DELETE_FAILED = 'RESOURCE_DELETE_FAILED';

// =====================================================================
// POLICIES
// =====================================================================

export const ADD_POLICY = 'ADD_POLICY';
export const UPDATE_POLICY = 'UPDATE_POLICY';
export const UNLOAD_POLICY = 'UNLOAD_POLICY';

export const FETCH_POLOCY_LIST = 'FETCH_POLOCY_LIST';
export const POLICY_FETCHED_SUCCESS = 'POLICY_FETCHED_SUCCESS';
export const POLICY_FETCHED_FAILED = 'POLICY_FETCHED_FAILED';

export const POLICY_TOGGLE_STATUS = 'POLICY_TOGGLE_STATUS';
export const POLICY_TOGGLE_SUCCESS = 'POLICY_TOGGLE_SUCCESS';
export const POLICY_TOGGLE_FAILED = 'POLICY_TOGGLE_FAILED';

export const ATTACH_ROLE_TO_POLICY = 'ATTACH_ROLE_TO_POLICY';
export const ATTACH_ROLE_TO_POLICY_SUCCESS = 'ATTACH_ROLE_TO_POLICY_SUCCESS';
export const ATTACH_ROLE_TO_POLICY_FAILED = 'ATTACH_ROLE_TO_POLICY_FAILED';

// =====================================================================
// ROLES
// =====================================================================

export const ADD_ROLE = 'ADD_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UNLOAD_ROLE = 'UNLOAD_ROLE';

export const FETCH_ROLE_LIST = 'FETCH_ROLE_LIST';
export const ROLE_FETCHED_SUCCESS = 'ROLE_FETCHED_SUCCESS';
export const ROLE_FETCHED_FAILED = 'ROLE_FETCHED_FAILED';

export const ROLE_TOGGLE_STATUS = 'ROLE_TOGGLE_STATUS';
export const ROLE_TOGGLE_SUCCESS = 'ROLE_TOGGLE_SUCCESS';
export const ROLE_TOGGLE_FAILED = 'ROLE_TOGGLE_FAILED';

export const ATTACH_USER_TO_ROLE = 'ATTACH_USER_TO_ROLE';

// =====================================================================
// USERS
// =====================================================================

export const CREATE_USER = 'CREATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UNLOAD_USER = 'UNLOAD_USER';

export const FETCH_USER_LIST = 'FETCH_USER_LIST';
export const USER_FETCHED_SUCCESS = 'USER_FETCHED_SUCCESS';
export const USER_FETCHED_FAILED = 'USER_FETCHED_FAILED';

export const USER_TOGGLE_STATUS = 'USER_TOGGLE_STATUS';
export const USER_TOGGLE_SUCCESS = 'USER_TOGGLE_SUCCESS';
export const USER_TOGGLE_FAILED = 'USER_TOGGLE_FAILED';

export const ATTACH_ROLE_TO_USER = 'ATTACH_ROLE_TO_USER';

// =====================================================================
// ADVERTISERS
// =====================================================================

export const CREATE_ADVERTISER = 'CREATE_ADVERTISER';
export const UPDATE_ADVERTISER = 'UPDATE_ADVERTISER';
export const UNLOAD_ADVERTISER = 'UNLOAD_ADVERTISER';

export const FETCH_ADVERTISER_LIST = 'FETCH_ADVERTISER_LIST';
export const ADVERTISER_FETCHED_SUCCESS = 'ADVERTISER_FETCHED_SUCCESS';
export const ADVERTISER_FETCHED_FAILED = 'ADVERTISER_FETCHED_FAILED';

// =====================================================================
// STORES
// =====================================================================

export const CREATE_STORE = 'CREATE_STORE';
export const UPDATE_STORE = 'UPDATE_STORE';
export const RESET_STORE = 'RESET_STORE';
export const UNLOAD_STORE = 'UNLOAD_STORE';

export const PUSHED_STORE = 'PUSHED_STORE';

export const FETCH_STORE = 'FETCH_STORE';
export const UPDATE_STORE_DATA = 'UPDATE_STORE_DATA';
export const STORE_FETCHING_SUCCESS = 'STORE_FETCHING_SUCCESS';
export const STORE_FETCHING_FAILED = 'STORE_FETCHING_FAILED';

export const FETCH_STORE_LIST = 'FETCH_STORE_LIST';
export const STORE_LIST_FETCHED_SUCCESS = 'STORE_LIST_FETCHED_SUCCESS';
export const STORE_LIST_FETCHED_FAILED = 'STORE_LIST_FETCHED_FAILED';

export const FETCH_ADMITAD_API_OFFER_LIST = 'FETCH_ADMITAD_API_OFFER_LIST';
export const FETCH_VCOMM_API_OFFER_LIST = 'FETCH_VCOMM_API_OFFER_LIST';

export const API_OFFER_SUCCESS = 'API_OFFER_SUCCESS';
export const API_OFFER_FAILED = 'API_OFFER_FAILED';

// =====================================================================
// CATEGORIES
// =====================================================================

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const RESET_CATEGORY = 'RESET_CATEGORY';

export const PUSHED_CATEGORY = 'PUSHED_CATEGORY';

export const CREATE_SUB_CATEGORY = 'CREATE_SUB_CATEGORY';
export const UPDATE_SUB_CATEGORY = 'UPDATE_SUB_CATEGORY';
export const RESET_SUB_CATEGORY = 'RESET_SUB_CATEGORY';

export const PUSHED_SUB_CATEGORY = 'PUSHED_SUB_CATEGORY';

export const UNLOAD_CATEGORY = 'UNLOAD_CATEGORY';

export const FETCH_CATEGORY_LIST = 'FETCH_CATEGORY_LIST';
export const CATEGORY_FETCHED_SUCCESS = 'CATEGORY_FETCHED_SUCCESS';
export const CATEGORY_FETCHED_FAILED = 'CATEGORY_FETCHED_FAILED';

export const FETCH_SUB_CATEGORY_LIST = 'FETCH_CATEGORY_LIST';
export const SUB_CATEGORY_FETCHED_SUCCESS = 'SUB_CATEGORY_FETCHED_SUCCESS';
export const SUB_CATEGORY_FETCHED_FAILED = 'SUB_CATEGORY_FETCHED_FAILED';

export const CATEGORY_TOGGLE_STATUS = 'CATEGORY_TOGGLE_STATUS';
export const CATEGORY_TOGGLE_SUCCESS = 'CATEGORY_TOGGLE_SUCCESS';
export const CATEGORY_TOGGLE_FAILED = 'CATEGORY_TOGGLE_FAILED';

export const SUB_CATEGORY_TOGGLE_STATUS = 'SUB_CATEGORY_TOGGLE_STATUS';
export const SUB_CATEGORY_TOGGLE_SUCCESS = 'SUB_CATEGORY_TOGGLE_SUCCESS';
export const SUB_CATEGORY_TOGGLE_FAILED = 'SUB_CATEGORY_TOGGLE_FAILED';

// =====================================================================
// OFFERS
// =====================================================================

export const CREATE_OFFER = 'CREATE_OFFER';
export const UPDATE_OFFER = 'UPDATE_OFFER';
export const RESET_OFFER = 'RESET_OFFER';
export const UNLOAD_OFFER = 'UNLOAD_OFFER';

export const PUSHED_OFFER = 'PUSHED_OFFER';

export const FETCH_OFFER_LIST = 'FETCH_OFFER_LIST';
export const OFFER_FETCHED_SUCCESS = 'OFFER_FETCHED_SUCCESS';
export const OFFER_FETCHED_FAILED = 'OFFER_FETCHED_FAILED';

export const UPDATE_ACTION_POOL = 'UPDATE_ACTION_POOL';

export const SAVE_PRESET = 'SAVE_PRESET';

export const OFFER_TOGGLE_STATUS = 'OFFER_TOGGLE_STATUS';
export const OFFER_TOGGLE_SUCCESS = 'OFFER_TOGGLE_SUCCESS';
export const OFFER_TOGGLE_FAILED = 'OFFER_TOGGLE_FAILED';

// =====================================================================
// BANNERS
// =====================================================================

export const CREATE_BANNER = 'CREATE_BANNER';
export const UPDATE_BANNER = 'UPDATE_BANNER';
export const RESET_BANNER = 'RESET_BANNER';
export const UNLOAD_BANNER = 'UNLOAD_BANNER';

export const PUSHED_BANNER = 'PUSHED_BANNER';

export const FETCH_BANNER_LIST = 'FETCH_BANNER_LIST';
export const BANNER_FETCHED_SUCCESS = 'BANNER_FETCHED_SUCCESS';
export const BANNER_FETCHED_FAILED = 'BANNER_FETCHED_FAILED';
export const BANNER_TOGGLE_STATUS = 'BANNER_TOGGLE_STATUS';
export const BANNER_TOGGLE_SUCCESS = 'BANNER_TOGGLE_SUCCESS';
export const BANNER_TOGGLE_FAILED = 'BANNER_TOGGLE_FAILED';

// =====================================================================
// TICKET
// =====================================================================

export const CREATE_TICKET = 'CREATE_TICKET';
export const UPDATE_TICKET = 'UPDATE_TICKET';
export const UNLOAD_TICKET = 'UNLOAD_TICKET';

export const FETCH_TICKET_LIST = 'FETCH_TICKET_LIST';
export const TICKET_FETCHED_SUCCESS = 'TICKET_FETCHED_SUCCESS';
export const TICKET_FETCHED_FAILED = 'TICKET_FETCHED_FAILED';

export const FETCH_COMMENTS = 'FETCH_COMMENTS';
export const COMMENTS_FETCH_SUCCESS = 'COMMENTS_FETCH_SUCCESS';
export const COMMENTS_FETCH_FAILED = 'COMMENTSFETCH_FAILED';

export const FETCH_EMAIL = 'FETCH_EMAIL';
export const EMAIL_FETCH_SUCCESS = 'EMAIL_FETCH_SUCCESS';
export const EMAIL_FETCH_FAILED = 'EMAIL_FETCH_FAILED';
export const SEND_EMAIL = 'SEND_EMAIL';
export const CLEAR_EMAIL = 'CLEAR_EMAIL';
export const ESCALATE_TICKET = 'ESCALATE_TICKET';

export const ADD_COMMENT = 'ADD_COMMENT';

// =====================================================================
// REMOTE CONFIG
// =====================================================================

export const ADD_REMOTE_CONFIG = 'ADD_REMOTE_CONFIG';
export const UPDATE_REMOTE_CONFIG = 'UPDATE_REMOTE_CONFIG';
export const UNLOAD_REMOTE_CONFIG = 'UNLOAD_REMOTE_CONFIG';

export const FETCH_REMOTE_CONFIG_LIST = 'FETCH_REMOTE_CONFIG_LIST';
export const REMOTE_CONFIG_FETCHED_SUCCESS = 'REMOTE_CONFIG_FETCHED_SUCCESS';
export const REMOTE_CONFIG_FETCHED_FAILED = 'REMOTE_CONFIG_FETCHED_FAILED';

export const PUSHED_REMOTE_CONFIG = 'PUSHED_REMOTE_CONFIG';

// =====================================================================
// CUSTOM CATEGORY
// =====================================================================

export const ADD_CUSTOM_CATEGORY = 'ADD_CUSTOM_CATEGORY';
export const UPDATE_CUSTOM_CATEGORY = 'UPDATE_CUSTOM_CATEGORY';
export const UNLOAD_CUSTOM_CATEGORY = 'UNLOAD_CUSTOM_CATEGORY';

export const FETCH_CUSTOM_CATEGORY_LIST = 'FETCH_CUSTOM_CATEGORY_LIST';
export const CUSTOM_CATEGORY_FETCHED_SUCCESS = 'CUSTOM_CATEGORY_FETCHED_SUCCESS';
export const CUSTOM_CATEGORY_FETCHED_FAILED = 'CUSTOM_CATEGORY_FETCHED_FAILED';

export const PUSHED_CUSTOM_CATEGORY = 'PUSHED_CUSTOM_CATEGORY';

// =====================================================================
// PRIME OFFERS
// =====================================================================

export const CREATE_PRIME_OFFER = 'CREATE_PRIME_OFFER';
export const UPDATE_PRIME_OFFER = 'UPDATE_PRIME_OFFER';
export const RESET_PRIME_OFFER = 'RESET_PRIME_OFFER';
export const UNLOAD_PRIME_OFFER = 'UNLOAD_PRIME_OFFER';

export const PUSHED_PRIME_OFFER = 'PUSHED_PRIME_OFFER';

export const FETCH_PRIME_OFFER_LIST = 'FETCH_PRIME_OFFER_LIST';
export const PRIME_OFFER_FETCHED_SUCCESS = 'PRIME_OFFER_FETCHED_SUCCESS';
export const PRIME_OFFER_FETCHED_FAILED = 'PRIME_OFFER_FETCHED_FAILED';

// =====================================================================
// ELITE OFFERS
// =====================================================================

export const CREATE_ELITE_OFFER = 'CREATE_ELITE_OFFER';
export const UPDATE_ELITE_OFFER = 'UPDATE_ELITE_OFFER';
export const RESET_ELITE_OFFER = 'RESET_ELITE_OFFER';
export const UNLOAD_ELITE_OFFER = 'UNLOAD_ELITE_OFFER';

export const FETCH_ELITE_OFFER_LIST = 'FETCH_ELITE_OFFER_LIST';
export const ELITE_OFFER_FETCHED_SUCCESS = 'ELITE_OFFER_FETCHED_SUCCESS';
export const ELITE_OFFER_FETCHED_FAILED = 'ELITE_OFFER_FETCHED_FAILED';

// =====================================================================
// PRIME OFFER CALLBACKS
// =====================================================================

export const FETCH_PRIME_OFFER_CALLBACKS_LIST = 'FETCH_PRIME_OFFER_CALLBACKS_LIST';
export const PRIME_OFFER_CALLBACKS_FETCHED_SUCCESS = 'PRIME_OFFER_CALLBACKS_FETCHED_SUCCESS';
export const PRIME_OFFER_CALLBACKS_FETCHED_FAILED = 'PRIME_OFFER_CALLBACKS_FETCHED_FAILED';
export const FETCH_PRIME_OFFER_CALLBACK = 'FETCH_PRIME_OFFER_CALLBACK';
export const PRIME_OFFER_CALLBACK_FETCHING_SUCCESS = 'PRIME_OFFER_CALLBACK_FETCHING_SUCCESS';
export const PRIME_OFFER_CALLBACK_FETCHING_FAILED = 'PRIME_OFFER_CALLBACK_FETCHING_FAILED';
export const UNLOAD_PRIME_OFFER_CALLBACKS = 'UNLOAD_PRIME_OFFER_CALLBACKS';

// =====================================================================
// ADDITIONAL CASHBACK
// =====================================================================

export const CREATE_ADDITIONAL_CASHBACK = 'CREATE_ADDITIONAL_CASHBACK';
export const UPDATE_ADDITIONAL_CASHBACK = 'UPDATE_ADDITIONAL_CASHBACK';
export const UNLOAD_ADDITIONAL_CASHBACK = 'UNLOAD_ADDITIONAL_CASHBACK';

export const FETCH_ADDITIONAL_CASHBACK_LIST = 'FETCH_ADDITIONAL_CASHBACK_LIST';
export const ADDITIONAL_CASHBACK_FETCHED_SUCCESS = 'ADDITIONAL_CASHBACK_FETCHED_SUCCESS';
export const ADDITIONAL_CASHBACK_FETCHED_FAILED = 'ADDITIONAL_CASHBACK_FETCHED_FAILED';

// =====================================================================
// USER REPORT
// =====================================================================

export const FETCH_USER_REPORTS_LIST = 'FETCH_USER_REPORTS_LIST';
export const USER_REPORT_FETCHED_SUCCESS = 'USER_REPORT_FETCHED_SUCCESS';
export const USER_REPORT_FETCHED_FAILED = 'USER_REPORT_FETCHED_FAILED';

// =====================================================================
// OFFLINE SALE REPORT
// =====================================================================

export const FETCH_OFFLINE_SALE_REPORTS_LIST = 'FETCH_OFFLINE_SALE_REPORTS_LIST';
export const OFFLINE_SALE_REPORTS_FETCHED_SUCCESS = 'OFFLINE_SALE_REPORTS_FETCHED_SUCCESS';
export const OFFLINE_SALE_REPORTS_FETCHED_FETCHED_FAILED = 'OFFLINE_SALE_REPORTS_FETCHED_FETCHED_FAILED';

export const FETCH_OFFLINE_PRIME_OFFER_REPORTS_LIST = 'FETCH_OFFLINE_PRIME_OFFER_REPORTS_LIST';
export const OFFLINE_PRIME_OFFER_REPORTS_FETCHED_SUCCESS = 'OFFLINE_PRIME_OFFER_REPORTS_FETCHED_SUCCESS';
export const OFFLINE_PRIME_OFFER_REPORTS_FETCHED_FETCHED_FAILED = 'OFFLINE_PRIME_OFFER_REPORTS_FETCHED_FETCHED_FAILED';

export const FETCH_OFFLINE_SHARE_OFFER_REPORTS_LIST = 'FETCH_OFFLINE_SHARE_OFFER_REPORTS_LIST';
export const OFFLINE_SHARE_OFFER_REPORTS_FETCHED_SUCCESS = 'OFFLINE_SHARE_OFFER_REPORTS_FETCHED_SUCCESS';
export const OFFLINE_SHARE_OFFER_REPORTS_FETCHED_FETCHED_FAILED = 'OFFLINE_SHARE_OFFER_REPORTS_FETCHED_FETCHED_FAILED';

export const FETCH_OFFLINE_ELITE_OFFER_REPORTS_LIST = 'FETCH_OFFLINE_ELITE_OFFER_REPORTS_LIST';
export const OFFLINE_ELITE_OFFER_REPORTS_FETCHED_SUCCESS = 'OFFLINE_ELITE_OFFER_REPORTS_FETCHED_SUCCESS';
export const OFFLINE_ELITE_OFFER_REPORTS_FETCHED_FETCHED_FAILED = 'OFFLINE_ELITE_OFFER_REPORTS_FETCHED_FETCHED_FAILED';
// =====================================================================
// REFERRAL CODE CHANGE
// =====================================================================

export const UPDATE_REFERRAL_CODE = 'UPDATE_REFERRAL_CODE';

// =====================================================================
// Custom Biller Category
// =====================================================================

export const CREATE_CUSTOM_BILLER_CATEGORY = 'CREATE_CUSTOM_BILLER_CATEGORY';
export const UPDATE_CUSTOM_BILLER_CATEGORY = 'UPDATE_CUSTOM_BILLER_CATEGORY';
export const UNLOAD_CUSTOM_BILLER_CATEGORY = 'UNLOAD_CUSTOM_BILLER_CATEGORY';
export const FETCH_CUSTOM_BILLER_CATEGORY_LIST = 'FETCH_CUSTOM_BILLER_CATEGORY_LIST';
export const CUSTOM_BILLER_CATEGORY_FETCHED_SUCCESS = 'CUSTOM_BILLER_CATEGORY_FETCHED_SUCCESS';
export const CUSTOM_BILLER_CATEGORY_FETCHED_FAILED = 'CUSTOM_BILLER_CATEGORY_FETCHED_FAILED';
export const RESET_CUSTOM_BILLER_CATEGORY = 'RESET_CUSTOM_BILLER_CATEGORY';

// =====================================================================
// Custom Biller Category
// =====================================================================

export const CREATE_BILLER = 'CREATE_BILLER';
export const UPDATE_BILLER = 'UPDATE_BILLER';
export const UNLOAD_BILLER = 'UNLOAD_BILLER';
export const FETCH_BILLER_LIST = 'FETCH_BILLER_LIST';
export const BILLER_FETCHED_SUCCESS = 'BILLER_FETCHED_SUCCESS';
export const BILLER_FETCHED_FAILED = 'BILLER_FETCHED_FAILED';
export const RESET_BILLER = 'RESET_BILLER';

// =====================================================================
// Biller Category
// =====================================================================

export const UPDATE_BILLER_CATEGORY = 'UPDATE_BILLER_CATEGORY';
export const UNLOAD_BILLER_CATEGORY = 'UNLOAD_BILLER_CATEGORY';
export const FETCH_BILLER_CATEGORY_LIST = 'FETCH_BILLER_CATEGORY_LIST';
export const BILLER_CATEGORY_FETCHED_SUCCESS = 'BILLER_CATEGORY_FETCHED_SUCCESS';
export const BILLER_CATEGORY_FETCHED_FAILED = 'BILLER_CATEGORY_FETCHED_FAILED';
export const RESET_BILLER_CATEGORY = 'RESET_BILLER_CATEGORY';

// =====================================================================
// Refund
// =====================================================================

export const UPDATE_REFUND = 'UPDATE_REFUND';
export const UNLOAD_REFUND = 'UNLOAD_REFUND';
export const FETCH_REFUND_LIST = 'FETCH_REFUND_LIST';
export const REFUND_FETCHED_SUCCESS = 'REFUND_FETCHED_SUCCESS';
export const REFUND_FETCHED_FAILED = 'REFUND_FETCHED_FAILED';
export const RESET_REFUND = 'RESET_REFUND';

// =====================================================================
// Campaign
// =====================================================================
export const CREATE_CAMPAIGN = 'CREATE_CAMPAIGN';
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const UNLOAD_CAMPAIGN = 'UNLOAD_CAMPAIGN';
export const FETCH_CAMPAIGN_LIST = 'FETCH_CAMPAIGN_LIST';
export const CAMPAIGN_FETCHED_SUCCESS = 'CAMPAIGN_FETCHED_SUCCESS';
export const CAMPAIGN_FETCHED_FAILED = 'CAMPAIGN_FETCHED_FAILED';
export const RESET_CAMPAIGN = 'RESET_CAMPAIGN';
export const CAMPAIGN_TOGGLE_STATUS = 'CAMPAIGN_TOGGLE_STATUS';
export const CAMPAIGN_TOGGLE_SUCCESS = 'CAMPAIGN_TOGGLE_SUCCESS';
export const CAMPAIGN_TOGGLE_FAILED = 'CAMPAIGN_TOGGLE_FAILED';

// =====================================================================
// Cpat Campaign
// =====================================================================
export const CREATE_CPAT_CAMPAIGN = 'CREATE_CPAT_CAMPAIGN';
export const UPDATE_CPAT_CAMPAIGN = 'UPDATE_CPAT_CAMPAIGN';
export const UNLOAD_CPAT_CAMPAIGN = 'UNLOAD_CPAT_CAMPAIGN';
export const FETCH_CPAT_CAMPAIGN_LIST = 'FETCH_CPAT_CAMPAIGN_LIST';
export const CPAT_CAMPAIGN_FETCHED_SUCCESS = 'CPAT_CAMPAIGN_FETCHED_SUCCESS';
export const CPAT_CAMPAIGN_FETCHED_FAILED = 'CPAT_CAMPAIGN_FETCHED_FAILED';
export const RESET_CPAT_CAMPAIGN = 'RESET_CPAT_CAMPAIGN';
export const CPAT_CAMPAIGN_TOGGLE_STATUS = 'CPAT_CAMPAIGN_TOGGLE_STATUS';
export const CPAT_CAMPAIGN_TOGGLE_SUCCESS = 'CPAT_CAMPAIGN_TOGGLE_SUCCESS';
export const CPAT_CAMPAIGN_TOGGLE_FAILED = 'CPAT_CAMPAIGN_TOGGLE_FAILED';

// =====================================================================
// Influencer Config
// =====================================================================
export const CREATE_INFLUENCER = 'CREATE_INFLUENCER';
export const UPDATE_INFLUENCER = 'UPDATE_INFLUENCER';
export const UNLOAD_INFLUENCER = 'UNLOAD_INFLUENCER';
export const FETCH_INFLUENCER_LIST = 'FETCH_INFLUENCER_LIST';
export const INFLUENCER_FETCHED_SUCCESS = 'INFLUENCER_FETCHED_SUCCESS';
export const INFLUENCER_FETCHED_FAILED = 'INFLUENCER_FETCHED_FAILED';
export const RESET_INFLUENCER = 'RESET_INFLUENCER';
export const INFLUENCER_TOGGLE_STATUS = 'INFLUENCER_TOGGLE_STATUS';
export const INFLUENCER_TOGGLE_SUCCESS = 'INFLUENCER_TOGGLE_SUCCESS';
export const INFLUENCER_TOGGLE_FAILED = 'INFLUENCER_TOGGLE_FAILED';

// =====================================================================
// EXTRA OFFER CALLBACKS
// =====================================================================

export const CREATE_SHARE_OFFER = 'CREATE_SHARE_OFFER';
export const UPDATE_SHARE_OFFER = 'UPDATE_SHARE_OFFER';
export const RESET_SHARE_OFFER = 'RESET_SHARE_OFFER';
export const UNLOAD_SHARE_OFFER = 'UNLOAD_SHARE_OFFER';
export const PUSHED_SHARE_OFFER = 'PUSHED_SHARE_OFFER';
export const FETCH_SHARE_OFFER_LIST = 'FETCH_SHARE_OFFER_LIST';
export const SHARE_OFFER_FETCHED_SUCCESS = 'SHARE_OFFER_FETCHED_SUCCESS';
export const SHARE_OFFER_FETCHED_FAILED = 'SHARE_OFFER_FETCHED_FAILED';
