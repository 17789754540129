export const StatusColor = {
  RECEIVED: '#2a8ef4',
  IN_PROGRESS: 'purple',
  RESOLVED: '#8BC34A',
  REJECT: '#FF5722',
  CLOSED: '#8BC34A',
  ACTIVE: '#2a8ef4',
  DISABLED: '#f50',
  SUCCESS: 'green',
  HOLD: 'yellow',
  APPROVED: 'blue',
  PENDING: 'pink',
  DECLINED: 'red',
  CANCELLED: 'purple',
  ONHOLD: 'red',
  STARTED: '2a8ef4',
  COMPLETED: 'green',
  POSTBACK: 'blue',
  IS_OFFLINE: 'green',
  EXPIRED: '#c9c7c1',
  FAILED: 'red',
  FIRST_GOAL_MISMATCHED: '#2a8ef4',
  GOAL_ORDER_MISMATCHED: '#2a8ef4',
  GOAL_ALREADY_FAILED: '#2a8ef4',
  GOAL_ALREADY_EXPIRED: '#2a8ef4',
  GOAL_ALREADY_COMPLETED: '#2a8ef4',
  GOAL_LOCKED: '#2a8ef4',
  GOAL_NOT_FOUND: '#2a8ef4',
  INVALID_GOAL: '#2a8ef4',
  ALREADY_COMPLETED: '#2a8ef4',
  CALLBACK_NOT_FOUND: '#2a8ef4',
  CLICK_NOT_EXIST: '#2a8ef4',
  CONVERSION_REACHED: '#2a8ef4',
  INVALID_CLICK_ID: '#2a8ef4',
  MISSING_CLICK_ID: '#2a8ef4',
  NOT_ALLOWED_IP: '#2a8ef4',
  GOAL_CTIT_FAILED: '#2a8ef4',
  CTIT_FAILED: '#2a8ef4',
  PRE_CONDITION_FAILED: '#2a8ef4',
  EMPTY_INSTALLED_AT: '#2a8ef4',
  UNAUTHORIZED: '#2a8ef4',
  INVALID_UUID: '#2a8ef4',
  PROCESSING: '#2a8ef4',
  EXCEPTION: '#2a8ef4',
};

export const OffersCouponColor = {
  PRESENT: 'green',
  ABSENT: 'red',
};

export const CategoryTypeColor = {
  PARENT: '#2db7f5',
  CUSTOM: '#87d068',
};

export const ActiveColor = {
  0: 'red',
  1: 'blue',
  INACTIVE: 'red',
  ACTIVE: 'blue',
};

export const PriorityColor = {
  HIGH: 'red',
  MEDIUM: 'purple',
  LOW: 'green',
};

export const NewtorkColor = {
  MANUAL: 'purple',
  FLIPKART: 'red',
  ADMITAD: '#2db7f5',
  VCOMMISSION: '#8BC34A',
  PRIMEDIGITAL_KC: 'blue',
  OPTIMIZE: 'green',
};

export const CallbackStatusColor = {
  PROCESSED: '#2a8ef4',
  PENDING: 'purple',
  DUPLICATE: '#f50',
};

export const AdditionalCashbackStatusColor = {
  PENDING: '#2a8ef4',
  APPROVED: '#8BC34A',
  DISCARDED: '#FF5722',
};

export const ReportColor = {
  IN_PROGRESS: 'blue',
  COMPLETED: 'green',
};
export const BlockedStatusColor = {
  DISABLED: 'red',
  ACTIVE: 'green',
};
