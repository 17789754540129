import { lazy } from 'react';
import {
  EditOutlined,
  PlusOutlined,
  BarsOutlined,
  HomeOutlined,
  SettingOutlined,
  MailOutlined,
} from '@ant-design/icons';

/*
  Format for route
  {
    invisible: {Boolean} if true it won't be shown in sidebar
    path: {String} url to be matched
    exact: {Boolean} for react-router exact
    icon: {Component | Icon}
    sidebar: {String} to be shown on sidebar
    component: {Component} a react component to be rendered if path match
    child: {Array} array of routes for sub-routing,
    checkPermission: {Func} { optional } check to verify current route permission
  }
 */

const Home = lazy(() => import('./views/pages/Home'));
const PageNotFound = lazy(() => import('./views/pages/PageNotFound'));

const Resource = {
  create: lazy(() => import('./views/pages/resource/CreateResource')),
  edit: lazy(() => import('./views/pages/resource/CreateResource')),
  list: lazy(() => import('./views/pages/resource/ListResources')),
};

const Policy = {
  create: lazy(() => import('./views/pages/policy/CreatePolicy')),
  edit: lazy(() => import('./views/pages/policy/CreatePolicy')),
  list: lazy(() => import('./views/pages/policy/ListPolicies')),
};

const Role = {
  create: lazy(() => import('./views/pages/role/CreateRole')),
  edit: lazy(() => import('./views/pages/role/CreateRole')),
  list: lazy(() => import('./views/pages/role/ListRoles')),
};

const User = {
  create: lazy(() => import('./views/pages/user/CreateUser')),
  edit: lazy(() => import('./views/pages/user/CreateUser')),
  list: lazy(() => import('./views/pages/user/ListUsers')),
  changePassword: lazy(() => import('./views/pages/user/ChangePassword')),
};

const Advertiser = {
  create: lazy(() => import('./views/pages/advertiser/CreateAdvertiser')),
  edit: lazy(() => import('./views/pages/advertiser/CreateAdvertiser')),
  list: lazy(() => import('./views/pages/advertiser/ListAdvertisers')),
};

const Store = {
  create: lazy(() => import('./views/pages/store/CreateStore')),
  edit: lazy(() => import('./views/pages/store/CreateStore')),
  list: lazy(() => import('./views/pages/store/ListStores')),
};

const Category = {
  create: lazy(() => import('./views/pages/category/CreateCategory')),
  edit: lazy(() => import('./views/pages/category/CreateCategory')),
  list: lazy(() => import('./views/pages/category/ListCategory')),
};

const SubCategory = {
  create: lazy(() => import('./views/pages/category/CreateSubCategory')),
  edit: lazy(() => import('./views/pages/category/CreateSubCategory')),
  list: lazy(() => import('./views/pages/category/ListSubCategory')),
};

const Offer = {
  create: lazy(() => import('./views/pages/offer/CreateOffer')),
  edit: lazy(() => import('./views/pages/offer/CreateOffer')),
  list: lazy(() => import('./views/pages/offer/ListOffers')),
};

const Banner = {
  create: lazy(() => import('./views/pages/banner/CreateBanner')),
  edit: lazy(() => import('./views/pages/banner/CreateBanner')),
  list: lazy(() => import('./views/pages/banner/ListBanners')),
};

const RemoteConfig = {
  create: lazy(() => import('./views/pages/remote-config/CreateRemoteConfig')),
  edit: lazy(() => import('./views/pages/remote-config/CreateRemoteConfig')),
  list: lazy(() => import('./views/pages/remote-config/ListRemoteConfig')),
};

const CustomCategory = {
  create: lazy(() => import('./views/pages/custom-category/CreateCustomCategory')),
  edit: lazy(() => import('./views/pages/custom-category/CreateCustomCategory')),
  list: lazy(() => import('./views/pages/custom-category/ListCustomCategory')),
  createJTBD: lazy(() => import('./views/pages/custom-category/CreateJTBD')),
  editJTBD: lazy(() => import('./views/pages/custom-category/CreateJTBD')),
  listJTBD: lazy(() => import('./views/pages/custom-category/ListJTBD')),
};

const PrimeOffers = {
  create: lazy(() => import('./views/pages/prime-offers/CreatePrimeOffers')),
  edit: lazy(() => import('./views/pages/prime-offers/CreatePrimeOffers')),
  list: lazy(() => import('./views/pages/prime-offers/ListPrimeOffers')),
};

const EliteOffers = {
  create: lazy(() => import('./views/pages/elite-offers/CreateEliteOffers')),
  edit: lazy(() => import('./views/pages/elite-offers/CreateEliteOffers')),
  list: lazy(() => import('./views/pages/elite-offers/ListEliteOffers')),
  dashboard: lazy(() => import('./views/pages/elite-offers/EliteOffersDashboard')),
  dashboardVerification: lazy(() => import('./views/pages/elite-offers/EliteOffersDashboardVerification')),
  dashboardDisplay: lazy(() => import('./views/pages/elite-offers/EliteOffersDashboardDisplay')),
};

const PrimeOfferCallbacks = {
  list: lazy(() => import('./views/pages/prime-offer-callbacks/ListPrimeOfferCallbacks')),
  edit: lazy(() => import('./views/pages/prime-offer-callbacks/CreatePrimeOfferCallbacks')),
};

const AdditionalCashback = {
  create: lazy(() => import('./views/pages/additional-cashback/CreateAdditionalCashback')),
  edit: lazy(() => import('./views/pages/additional-cashback/CreateAdditionalCashback')),
  list: lazy(() => import('./views/pages/additional-cashback/ListAdditionalCashback')),
};

const RererralCode = {
  create: lazy(() => import('./views/pages/referral-code/CreateReferralCode')),
  edit: lazy(() => import('./views/pages/referral-code/CreateReferralCode')),
};

const UserReporting = {
  create: lazy(() => import('./views/pages/user-reporting/CreateUserReport')),
  list: lazy(() => import('./views/pages/user-reporting/ListUserReports')),
};

const OfflineSalesReports = {
  create: lazy(() => import('./views/pages/offline-sale-reporting/CreateOfflineSalesReporting')),
  list: lazy(() => import('./views/pages/offline-sale-reporting/ListOfflineSalesReporting')),
};

const OfflinePrimeOfferReports = {
  create: lazy(() => import('./views/pages/offline-sale-reporting/CreatePrimeOfferOfflineReporting')),
  list: lazy(() => import('./views/pages/offline-sale-reporting/ListPrimeOfferOfflineReporting')),
};

const OfflineShareOfferReports = {
  create: lazy(() => import('./views/pages/offline-sale-reporting/CreateShareOfferOfflineReporting')),
  list: lazy(() => import('./views/pages/offline-sale-reporting/ListShareOfferOfflineReporting')),
};

const OfflineEliteOfferReports = {
  create: lazy(() => import('./views/pages/offline-sale-reporting/CreateEliteOfferOfflineReporting')),
  list: lazy(() => import('./views/pages/offline-sale-reporting/ListEliteOfferOfflineReporting')),
};

const CustomBillerCategory = {
  create: lazy(() => import('./views/pages/payment-dashboard/custom-biller-category/CreateCustomBillerCategory')),
  edit: lazy(() => import('./views/pages/payment-dashboard/custom-biller-category/CreateCustomBillerCategory')),
  list: lazy(() => import('./views/pages/payment-dashboard/custom-biller-category/ListCustomBillerCategory')),
};

const Biller = {
  create: lazy(() => import('./views/pages/payment-dashboard/biller/CreateBiller')),
  edit: lazy(() => import('./views/pages/payment-dashboard/biller/CreateBiller')),
  list: lazy(() => import('./views/pages/payment-dashboard/biller/ListBiller')),
};

const BillerCategoryMapper = {
  edit: lazy(() => import('./views/pages/payment-dashboard/biller-category/CreateBillerCategory')),
  list: lazy(() => import('./views/pages/payment-dashboard/biller-category/ListBillerCategory')),
};

const Refund = {
  edit: lazy(() => import('./views/pages/payment-dashboard/refund/EditRefund')),
  list: lazy(() => import('./views/pages/payment-dashboard/refund/ListRefund')),
};

const Campaign = {
  create: lazy(() => import('./views/pages/campaign/CreateCampaign')),
  edit: lazy(() => import('./views/pages/campaign/CreateCampaign')),
  list: lazy(() => import('./views/pages/campaign/ListCampaign')),
};

const CpatCampaign = {
  create: lazy(() => import('./views/pages/cpat-campaign/CreateCpatCampaign')),
  edit: lazy(() => import('./views/pages/cpat-campaign/CreateCpatCampaign')),
  list: lazy(() => import('./views/pages/cpat-campaign/ListCpatCampaign')),
};

const Influencer = {
  create: lazy(() => import('./views/pages/influencer/CreateInfluencerConfig')),
  edit: lazy(() => import('./views/pages/influencer/CreateInfluencerConfig')),
  list: lazy(() => import('./views/pages/influencer/ListInfluencerConfig')),
};

const ShareOffers = {
  create: lazy(() => import('./views/pages/share-offers/CreateShareOffers')),
  edit: lazy(() => import('./views/pages/share-offers/CreateShareOffers')),
  list: lazy(() => import('./views/pages/share-offers/ListShareOffers')),
  clickList: lazy(() => import('./views/pages/share-offers/ListClickDashboard')),
};

const common = [
  {
    path: '/',
    icon: HomeOutlined,
    exact: true,
    name: 'home',
    sidebar: 'Dashboard',
    component: Home,
  },
  {
    path: '/404',
    exact: true,
    name: 'pageNotFound',
    sidebar: 'PageNotFound',
    invisible: true,
    component: PageNotFound,
  },
];

const auth = [
  {
    path: '/resource',
    sidebar: 'Resources',
    name: 'resource',
    checkPermission: (permission) => permission.Resource,
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: Resource.create,
        checkPermission: (permission) => permission.Resource.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: Resource.edit,
        checkPermission: (permission) => permission.Resource.update,
      },
      {
        path: 's',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: Resource.list,
        checkPermission: (permission) => permission.Resource.list,
      },
    ],
  },
  {
    path: '/policies',
    sidebar: 'Policy',
    name: 'policy',
    checkPermission: (permission) => permission.Policy,
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: Policy.create,
        checkPermission: (permission) => permission.Policy.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: Policy.edit,
        checkPermission: (permission) => permission.Policy.view,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: Policy.list,
        checkPermission: (permission) => permission.Policy.list,
      },
    ],
  },
  {
    path: '/roles',
    sidebar: 'Role',
    name: 'role',
    checkPermission: (permission) => permission.Role,
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: Role.create,
        checkPermission: (permission) => permission.Role.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: Role.edit,
        checkPermission: (permission) => permission.Role.view,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: Role.list,
        checkPermission: (permission) => permission.Role.list,
      },
    ],
  },
  {
    path: '/users',
    sidebar: 'User',
    name: 'user',
    checkPermission: (permission) => permission.User,
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: User.create,
        checkPermission: (permission) => permission.User.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: User.edit,
        checkPermission: (permission) => permission.User.view,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: User.list,
        checkPermission: (permission) => permission.User.list,
      },
    ],
  },
  {
    path: '/profile',
    sidebar: 'Profile',
    name: 'user',
    child: [
      {
        path: '/change-password',
        exact: true,
        sidebar: 'Change Password',
        icon: EditOutlined,
        name: 'change-password',
        component: User.changePassword,
      },
    ],
  },
];

const cms = [
  {
    path: '/user-report',
    sidebar: 'User Report',
    name: 'Userreport',
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: UserReporting.create,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: UserReporting.list,
      },
    ],
  },
  {
    path: '/offline_sales',
    sidebar: 'Offline Sales',
    name: 'OfflineSales',
    child: [
      {
        path: '/create-shopping',
        exact: true,
        sidebar: 'Create:Shopping',
        icon: PlusOutlined,
        name: 'create',
        component: OfflineSalesReports.create,
      },
      {
        path: '/list-shopping',
        exact: true,
        sidebar: 'List:Shopping',
        icon: BarsOutlined,
        name: 'list',
        component: OfflineSalesReports.list,
      },
      {
        path: '/create-prime-offer',
        exact: true,
        sidebar: 'Create:PrimeOffer',
        icon: PlusOutlined,
        name: 'create',
        component: OfflinePrimeOfferReports.create,
      },
      {
        path: '/list-prime-offer',
        exact: true,
        sidebar: 'List:PrimeOffer',
        icon: BarsOutlined,
        name: 'list',
        component: OfflinePrimeOfferReports.list,
      },
      {
        path: '/create-elite-offer',
        exact: true,
        sidebar: 'Create:EliteOffer',
        icon: PlusOutlined,
        name: 'create',
        component: OfflineEliteOfferReports.create,
      },
      {
        path: '/list-elite-offer',
        exact: true,
        sidebar: 'List:EliteOffer',
        icon: BarsOutlined,
        name: 'list',
        component: OfflineEliteOfferReports.list,
      },
      {
        path: '/create-share-offer',
        exact: true,
        sidebar: 'Create:ShareOffer',
        icon: PlusOutlined,
        name: 'create',
        component: OfflineShareOfferReports.create,
      },
      {
        path: '/list-share-offer',
        exact: true,
        sidebar: 'List:ShareOffer',
        icon: BarsOutlined,
        name: 'list',
        component: OfflineShareOfferReports.list,
      },
    ],
  },
  {
    path: '/advertisers',
    sidebar: 'Advertiser',
    name: 'advertiser',
    checkPermission: (permission) => permission.Advertiser,
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: Advertiser.create,
        checkPermission: (permission) => permission.Advertiser.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: Advertiser.edit,
        checkPermission: (permission) => permission.Advertiser.view,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: Advertiser.list,
        checkPermission: (permission) => permission.Advertiser.list,
      },
    ],
  },
  {
    path: '/stores',
    sidebar: 'Store',
    name: 'store',
    checkPermission: (permission) => permission.Store,
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: Store.create,
        checkPermission: (permission) => permission.Store.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: Store.edit,
        checkPermission: (permission) => permission.Store.view,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: Store.list,
        checkPermission: (permission) => permission.Store.list,
      },
    ],
  },
  {
    path: '/offers',
    sidebar: 'Offer',
    name: 'offer',
    checkPermission: (permission) => permission.Offer,
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: Offer.create,
        checkPermission: (permission) => permission.Offer.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: Offer.edit,
        checkPermission: (permission) => permission.Offer.view,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: Offer.list,
        checkPermission: (permission) => permission.Offer.list,
      },
    ],
  },
  {
    path: '/banners',
    sidebar: 'Banner',
    name: 'banners',
    checkPermission: (permission) => permission.Banner,
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: Banner.create,
        checkPermission: (permission) => permission.Banner.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: Banner.edit,
        checkPermission: (permission) => permission.Banner.view,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: Banner.list,
        checkPermission: (permission) => permission.Banner.list,
      },
    ],
  },
  {
    path: '/categories',
    sidebar: 'Category',
    name: 'category',
    checkPermission: (permission) => permission.Category,
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: Category.create,
        checkPermission: (permission) => permission.Category.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: Category.edit,
        checkPermission: (permission) => permission.Category.view,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: Category.list,
        checkPermission: (permission) => permission.Category.list,
      },
    ],
  },
  {
    path: '/sub-categories',
    sidebar: 'Sub Category',
    name: 'subcategory',
    checkPermission: (permission) => permission.SubCategory,
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: SubCategory.create,
        checkPermission: (permission) => permission.SubCategory.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: SubCategory.edit,
        checkPermission: (permission) => permission.SubCategory.view,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: SubCategory.list,
        checkPermission: (permission) => permission.SubCategory.list,
      },
    ],
  },
  {
    path: '/additional-cashback',
    sidebar: 'Additional Cashback',
    name: 'additionalCashback',
    icon: MailOutlined,
    checkPermission: (permission) => permission.AdditionalCashback,
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create Reward',
        icon: PlusOutlined,
        name: 'createReward',
        component: AdditionalCashback.create,
        checkPermission: (permission) => permission.AdditionalCashback.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: AdditionalCashback.edit,
        checkPermission: (permission) => permission.AdditionalCashback.view,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: AdditionalCashback.list,
        checkPermission: (permission) => permission.AdditionalCashback.list,
      },
    ],
  },
  {
    path: '/referral_code',
    sidebar: 'Referral Code',
    name: 'ReferralCode',
    checkPermission: (permission) => permission.ReferralCode,
    child: [
      {
        path: '/update',
        exact: true,
        sidebar: 'Update Referral',
        icon: PlusOutlined,
        name: 'updateReward',
        component: RererralCode.create,
        checkPermission: (permission) => permission.ReferralCode.create,
      },
    ],
  },
  {
    path: '/prime-offers',
    sidebar: 'Prime Offers',
    name: 'primeOffers',
    checkPermission: (permission) => permission.PrimeOffer,
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: PrimeOffers.create,
        checkPermission: (permission) => permission.PrimeOffer.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: PrimeOffers.edit,
        checkPermission: (permission) => permission.PrimeOffer.view,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: PrimeOffers.list,
        checkPermission: (permission) => permission.PrimeOffer.list,
      },
    ],
  },
  {
    path: '/elite-offers',
    sidebar: 'Elite Offers',
    name: 'eliteOffers',
    checkPermission: (permission) => permission.EliteOffer,
    child: [
      {
        path: '/dashboard',
        exact: true,
        sidebar: 'Dashboard',
        invisible: true,
        icon: HomeOutlined,
        name: 'dashboard',
        component: EliteOffers.dashboard,
        checkPermission: (permission) => permission.EliteOffer.list,
      },
      {
        path: '/dashboard-verification',
        exact: true,
        sidebar: 'Dashboard',
        icon: HomeOutlined,
        name: 'dashboard',
        component: EliteOffers.dashboardVerification,
        checkPermission: (permission) => permission.EliteOffer.list,
      },
      {
        path: '/dashboard-display',
        sidebar: 'DashboardDisplay',
        invisible: true,
        name: 'dashboard-display',
        component: EliteOffers.dashboardDisplay,
        checkPermission: (permission) => permission.EliteOffer.list,
      },
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: EliteOffers.create,
        checkPermission: (permission) => permission.EliteOffer.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: EliteOffers.edit,
        checkPermission: (permission) => permission.EliteOffer.view,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: EliteOffers.list,
        checkPermission: (permission) => permission.EliteOffer.list,
      },
    ],
  },
  {
    path: '/share-offer',
    sidebar: 'Share Offers',
    name: 'ShareOffers',
    checkPermission: (permission) => permission.ShareOffer,
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: ShareOffers.create,
        checkPermission: (permission) => permission.ShareOffer.create,

      },
      {
        path: '/click-dashboard',
        exact: true,
        sidebar: 'Click List',
        icon: BarsOutlined,
        name: 'list',
        component: ShareOffers.clickList,
        checkPermission: (permission) => permission.ShareOffer.list,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: ShareOffers.edit,
        checkPermission: (permission) => permission.ShareOffer.view,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: ShareOffers.list,
        checkPermission: (permission) => permission.ShareOffer.list,
      },
    ],
  },
];

const misc = [
  {
    path: '/custom-category',
    sidebar: 'CustomCategory',
    name: 'custom-category',
    checkPermission: (permission) => permission.CustomCategory,
    child: [
      {
        path: '/custom/create',
        exact: true,
        sidebar: 'Create:Custom',
        icon: PlusOutlined,
        name: 'create:Custom',
        component: CustomCategory.create,

        checkPermission:
          (permission) => permission.CustomCategory && permission.CustomCategory.create,
      },
      {
        path: '/jtbd/create',
        exact: true,
        sidebar: 'Create:JTBD',
        icon: PlusOutlined,
        name: 'create:JTBD',
        component: CustomCategory.createJTBD,

        checkPermission:
          (permission) => permission.CustomCategory && permission.CustomCategory.create,
      },
      {
        path: '/custom/:id',
        sidebar: 'Edit:Custom',
        invisible: true,
        name: 'edit:Custom',
        component: CustomCategory.edit,

        checkPermission:
          (permission) => permission.CustomCategory && permission.CustomCategory.view,
      },
      {
        path: '/jtbd/:id',
        sidebar: 'Edit:JTBD',
        invisible: true,
        name: 'edit:JTBD',
        component: CustomCategory.editJTBD,

        checkPermission:
          (permission) => permission.CustomCategory && permission.CustomCategory.view,
      },
      {
        path: '/custom',
        exact: true,
        sidebar: 'List:Custom',
        icon: BarsOutlined,
        name: 'list:Custom',
        component: CustomCategory.list,

        checkPermission:
          (permission) => permission.CustomCategory && permission.CustomCategory.list,
      },
      {
        path: '/jtbd',
        exact: true,
        sidebar: 'List:JTBD',
        icon: BarsOutlined,
        name: 'list:JTBD',
        component: CustomCategory.listJTBD,

        checkPermission:
          (permission) => permission.CustomCategory && permission.CustomCategory.list,
      },
    ],
  },
  {
    path: '/remote-config',
    sidebar: 'RemoteConfig',
    name: 'remote-config',
    icon: SettingOutlined,
    checkPermission: (permission) => permission.RemoteConfig,
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: RemoteConfig.create,
        checkPermission: (permission) => permission.RemoteConfig && permission.RemoteConfig.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: RemoteConfig.edit,
        checkPermission: (permission) => permission.RemoteConfig && permission.RemoteConfig.view,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: RemoteConfig.list,
        checkPermission: (permission) => permission.RemoteConfig && permission.RemoteConfig.list,
      },
    ],
  },
  {
    path: '/prime-offer-callbacks',
    sidebar: 'PrimeOfferCallbacks',
    name: 'prime-offer-callbacks',
    child: [
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: PrimeOfferCallbacks.list,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: PrimeOfferCallbacks.edit,

      },
    ],
  },
  {
    path: '/influencer',
    sidebar: 'Influencer',
    name: 'Influencer',
    checkPermission: (permission) => permission.Influencer,
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: Influencer.create,
        checkPermission: (permission) => permission.Influencer.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: Influencer.edit,
        checkPermission: (permission) => permission.Influencer.view,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: Influencer.list,
        checkPermission: (permission) => permission.Influencer.list,
      },
    ],
  },
  {
    path: '/campaign-cpat',
    sidebar: 'CpatCampaign',
    name: 'cpat-campaign',
    checkPermission: (permission) => permission.CpatCampaign,
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: CpatCampaign.create,
        checkPermission: (permission) => permission.CpatCampaign.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: CpatCampaign.edit,
        checkPermission: (permission) => permission.CpatCampaign.view,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: CpatCampaign.list,
        checkPermission: (permission) => permission.CpatCampaign.list,
      },
    ],
  },
  {
    path: '/campaigns',
    sidebar: 'Campaign',
    name: 'campaign',
    checkPermission: (permission) => permission.Campaign,
    child: [
      {
        path: '/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: Campaign.create,
        checkPermission: (permission) => permission.Campaign.create,
      },
      {
        path: '/:id',
        sidebar: 'Edit',
        invisible: true,
        name: 'edit',
        component: Campaign.edit,
        checkPermission: (permission) => permission.Campaign.view,
      },
      {
        path: '',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: Campaign.list,
        checkPermission: (permission) => permission.Campaign.list,
      },
    ],
  },
];

// This Routes is for Payment Dashboard Only
const payment = [
  {
    path: '/payment-dashboard',
    exact: true,
    icon: HomeOutlined,
    name: 'payment-dashboard',
    sidebar: 'Payment Dashboard',
    component: Home,
  },
  {
    path: '/payment-dashboard',
    sidebar: 'Add Biller',
    name: 'biller',
    child: [
      {
        path: '/biller/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: Biller.create,
      },
      {
        path: '/biller/:id',
        exact: true,
        invisible: true,
        icon: PlusOutlined,
        name: 'edit',
        component: Biller.edit,
      },
      {
        path: '/biller',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: Biller.list,
      },
    ],
  },
  {
    path: '/payment-dashboard',
    sidebar: 'Biller Custom Category',
    name: 'biller-custom-category',
    child: [
      {
        path: '/biller-custom-category/create',
        exact: true,
        sidebar: 'Create',
        icon: PlusOutlined,
        name: 'create',
        component: CustomBillerCategory.create,
      },
      {
        path: '/biller-custom-category/:id',
        exact: true,
        invisible: true,
        icon: PlusOutlined,
        name: 'edit',
        component: CustomBillerCategory.edit,
      },
      {
        path: '/biller-custom-category',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: CustomBillerCategory.list,
      },
      {
        path: '/',
        exact: true,
        sidebar: 'Home',
        invisible: true,
        icon: PlusOutlined,
        name: 'home',
        component: Home,
      },
    ],
  },
  {
    path: '/payment-dashboard',
    sidebar: 'Biller Category',
    name: 'biller-category',
    child: [
      {
        path: '/biller-category/:id',
        exact: true,
        invisible: true,
        icon: PlusOutlined,
        name: 'edit',
        component: BillerCategoryMapper.edit,
      },
      {
        path: '/biller-category',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: BillerCategoryMapper.list,
      },
    ],
  },
  {
    path: '/payment-dashboard',
    sidebar: 'Refund',
    name: 'refund',
    child: [
      {
        path: '/refund/:id',
        exact: true,
        invisible: true,
        icon: PlusOutlined,
        name: 'edit',
        component: Refund.edit,
      },
      {
        path: '/refund',
        exact: true,
        sidebar: 'List',
        icon: BarsOutlined,
        name: 'list',
        component: Refund.list,
      },
    ],
  },
];

const routes = [...common, ...auth, ...cms, ...misc];

const extractName = (list, _parent) => {
  const parent = { name: '', path: '', ..._parent };

  const newList = list.map((item) => {
    // if child don't need to return parent name
    if (item.child && item.child.length) {
      return extractName(item.child, item);
    }
    return {
      name: parent.name ? `${parent.name}.${item.name}` : item.name,
      path: parent.path + item.path,
    };
  });

  return newList;
};

const namedRoutes = {};
extractName(routes)
  .flat()
  .forEach((item) => {
    namedRoutes[item.name] = item.path;
  });

extractName(payment)
  .flat()
  .forEach((item) => {
    namedRoutes[item.name] = item.path;
  });

export const getUrl = (name, params = {}) => {
  let url = namedRoutes[name];

  Object.keys(params).forEach((key) => {
    url = url.replace(`:${key}`, params[key]);
  });

  return url;
};

const routesData = {
  routes,
  payment,
};

export default routesData;
